<template>
  <section id="product-history">
    <breadcrumbs class="breadcrumbs" />
    <b-card
      no-body
      class="mt-3 p-0"
    >
      <div class="b-filter-date p-3">
        <date-picker
          class="elm-calendar"
          type="daterange"
          placeholder="Выберите дату"
          :clearable="false"
        />
      </div>
      <b-table
        ref="table_history"
        :fields="fields"
        :items="history_data"
        :busy="tableIsBusy"
        empty-filtered-text="Нет товаров"
        show-empty
      >
        <template #table-busy>
          <div class="text-center">
            <b-spinner
              variant="primary"
              label="Загрузка..."
            />
            <div><strong>Загрузка...</strong></div>
          </div>
        </template>

        <template #head(id)>
          <b-form-checkbox v-model="select_all" />
        </template>

        <template #cell(id)="data">
          <b-form-checkbox
            v-model="selected"
            class="cursor"
            :value="data.value"
          />
        </template>

        <template #empty="scope">
          <p class="center mt-3">{{ scope.emptyFilteredText }}</p>
        </template>
      </b-table>
    </b-card>
  </section>
</template>

<script>
  import Breadcrumbs from '@breadcrumbs'

  export default {
    components: { Breadcrumbs },

    data: () => ({
      tableIsBusy: false,
      select_all: false,
      selected: [],
      history_data: [
        {
          id: 1,
          date: '01.04.2021',
          manager: 'Иванов И.И',
          comment: 'Съешь ещё этих мягкихфранцузских булок, да выпей же чаю'
        },
        {
          id: 2,
          date: '02.04.2021',
          manager: 'Петров И.И',
          comment: 'Съешь ещё этих мягкихфранцузских булок, да выпей же чаю'
        },
        {
          id: 3,
          date: '03.04.2021',
          manager: 'Сидоров И.И',
          comment: 'Съешь ещё этих мягкихфранцузских булок, да выпей же чаю'
        },
        {
          id: 4,
          date: '04.04.2021',
          manager: 'Петров И.И',
          comment: 'Съешь ещё этих мягкихфранцузских булок, да выпей же чаю'
        },
        {
          id: 5,
          date: '05.04.2021',
          manager: 'Сидоров И.И',
          comment: 'Съешь ещё этих мягкихфранцузских булок, да выпей же чаю'
        }
      ]
    }),

    computed: {
      fields: function () {
        return [
          {
            key: 'id',
            label: '',
            isRowHeader: true,
            class: 'w-5 center'
          },
          {
            key: 'date',
            label: 'Дата документа',
            class: 'w-20',
            sortable: true
          },
          {
            key: 'manager',
            label: 'Менеджер',
            class: 'w-20',
            sortable: true
          },
          {
            key: 'comment',
            label: 'Комментарий'
          }
        ]
      }
    },

    watch: {
      select_all(newVal) {
        if (!newVal) return (this.selected = [])
      }
    }
  }
</script>

<style lang="scss" scoped>
  #product-history {
    .elm-calendar {
      width: 300px;
    }
  }
</style>
